import React from 'react';
import PropTypes from 'prop-types';

import styles from './Hero.module.postcss';

const Hero = ({ children, photo }) => {
  return (
    <div className={styles.Hero}>
      <div
        className={styles.Hero_image}
        style={{ backgroundImage: `url("${photo}")` }}
      />
      <div className={styles.Hero_content}>
        {children}
      </div>
    </div>
  );
}

Hero.propTypes = {
  children: PropTypes.node,
  photo: PropTypes.string,
};

export default Hero;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

// Components
import Input from '../form/Input';

// Misc
import * as v from '../../modules/validators';

import styles from '../../../stylesheets/public/components/Forms.module.postcss';

class LocationSubscribeForm extends Component {

  constructor(props) {
    super();
  }

  render() {
    const { handleSubmit, onClose, valid, submitting } = this.props;

    return (
      <form
        onSubmit={handleSubmit}
        className={styles.FlexForm}
      >
        <div className="Fieldset">
          <Field
            label="Email"
            name="email"
            required
            component={Input}
            type="email"
            validate={[v.required]}
          />

          <div className="FormRow">
            <button
              type="submit"
              className="Button Button--small"
              disabled={!valid || submitting}
            >
              Submit
            </button>
            <button
              type="button"
              className="LinkButton u-ml1"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    );
  }
}

LocationSubscribeForm.propTypes = {
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  change: PropTypes.func,
  valid: PropTypes.bool,
  submitting: PropTypes.bool,
  pod: PropTypes.object,
};

export default reduxForm({ form: 'location_subscribe' })(LocationSubscribeForm);

import React from 'react';
import PropTypes from 'prop-types';

import styles from './Modal.module.postcss';

const Modal = ({ children, contentClassName }) => {
  return (
    <div className={styles.ModalContainer}>
      <div
        className={styles.ModalContent + ' ' + contentClassName}>
        {children}
      </div>
    </div>
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  contentClassName: PropTypes.string,
};

export default Modal;

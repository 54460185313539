import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Scroll from 'react-scroll';

// Components
import PublicEvents from './PublicEvents';
import Hero from './content/Hero';
import Sidebar from './Sidebar';
import Loader from '../shared/Loader';
import Ad from '../shared/Ad';
import Meta from '../shared/Meta';
import Modal from '../shared/Modal';
import LocationSubscribeForm from './LocationSubscribeForm';

// Actions
import { clearServerRenderedPath } from '../../actions/uiActions';
import locationActions from '../../actions/locationActions';

// Selectors
import { getLocation, getCityConfig } from '../../selectors';

// Misc
import IsoMorphUtils from '../../modules/isoMorphUtils';
import { getUrl, cityState } from '../../modules/helpers';
import styles from './Location.module.postcss';
import modalStyles from '../shared/Modal.module.postcss';
import sidebarStyles from '../../../stylesheets/public/layouts/SidebarLayout.module.postcss';

const OFFSET = -144;

class Location extends Component {
  constructor(props) {
    super(props);
    const match = props.computedMatch || props.match;
    this.id = match.params.id;
    this.openSubscribeModal = this.openSubscribeModal.bind(this);
    this.closeSubscribeModal = this.closeSubscribeModal.bind(this);
    this.openSubscribeSuccessModal = this.openSubscribeSuccessModal.bind(this);
    this.closeSubscribeSuccessModal = this.closeSubscribeSuccessModal.bind(this);
    this.onSubscribeSubmit = this.onSubscribeSubmit.bind(this);
    this.state = {
      ready: false || IsoMorphUtils.ready(props),
      showSubscribeModal: false,
      showSubscribeSuccessModal: false
    };
  }

  componentDidMount() {
    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    } else {
      this.props.clearLocations().then(() => {
        this.props.fetchLocation(this.id);
      });
      this.setState({ ready: true });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const currentMatch = this.props.computedMatch || this.props.match;
    const nextMatch = nextProps.computedMatch || nextProps.match;

    if ((currentMatch.params && nextMatch.params) &&
        (currentMatch.params.id !== nextMatch.params.id)) {
      this.id = nextMatch.params.id;
      this.props.clearLocations().then(() => {
        this.props.fetchLocation(this.id);
      });
    }
  }

  openSubscribeModal() {
    this.setState({ showSubscribeModal: true, showSubscribeSuccessModal: false });
  }

  closeSubscribeModal() {
    this.setState({ showSubscribeModal: false });
  }

  openSubscribeSuccessModal() {
    this.setState({ showSubscribeModal: false, showSubscribeSuccessModal: true });
  }

  closeSubscribeSuccessModal() {
    this.setState({ showSubscribeSuccessModal: false });
  }

  onSubscribeSubmit(values) {
    const { locationResource } = this.props;
    this.props.addSubscriber(locationResource.id, values.email).then((response) => {
      this.openSubscribeSuccessModal();
    });
    this.closeSubscribeModal();
  }

  render() {
    const { locationResource, location, cityConfig } = this.props;
    const { showSubscribeModal, showSubscribeSuccessModal } = this.state;

    if (!this.state.ready) return null;

    let image = 'https://static.seattlefoodtruck.com/photos/locationheader.png';
    if (locationResource && locationResource.photo) {
      image = `${IsoMorphUtils.railsContext.imageBase}/${locationResource.photo}`;
    }

    let name = '';
    if (locationResource) {
      name = locationResource.name ? locationResource.name : locationResource.filtered_address;
    }

    const url = getUrl();
    let title = cityConfig.site_title;
    let description = '';
    if (locationResource) {
      const cityStateValue = cityState(locationResource.address);
      const neighborhood = locationResource.neighborhood ? locationResource.neighborhood.name + " -" : "";
      title = `${name} - Food ${cityConfig.vehicle_type} Schedule - ${neighborhood} ${cityStateValue} - Food ${cityConfig.vehicle_type} Reviews - Phone Number - Menu`;
      if (locationResource.current_trucks) {
        description = `${locationResource.filtered_address} - ${locationResource.current_trucks}`;
      } else {
        description = `${locationResource.filtered_address}`;
      }
    }

    return (
      <div>
        {locationResource &&
          <Meta
            title={title}
            description={description}
            image={image}
            url={url}
            type="website"
            structuredData={{
              breadcrumbs: { listItems: [
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": locationResource.name,
                  "item": cityConfig.base_url + location.pathname,
                }
              ]}
            }}
          />
        }

        <Loader entities="location" actions="fetch">
          {locationResource &&
            <Hero photo={image}>
              <h1>{locationResource.name}</h1>
              <p>
                <a href={`https://www.google.com/maps/place/${locationResource.filtered_address}`} target="_blank">
                  {locationResource.filtered_address}
                </a>
              </p>
              {locationResource.pod &&
                <div>
                  { locationResource.pod.description &&
                    <p>{locationResource.pod.description}</p>
                  }
                </div>
              }
            </Hero>
          }
        </Loader>

        <div className={styles.LocationDetail_nav + " SecondaryHeader"}>
          <ul>
            <li>
              <Scroll.Link
                offset={OFFSET}
                smooth
                to="schedule"
              >
                Location schedule
              </Scroll.Link>
            </li>
            {locationResource && locationResource.pod &&
              <li className="u-mlAuto u-noMobile">
                <button
                  className={"Button " + styles.SubscribeButton}
                  onClick={this.openSubscribeModal}
                >Subscribe to Location</button>
              </li>
            }
          </ul>
        </div>

        {locationResource && locationResource.pod &&
          <div className="PaddedSection u-mobileOnly">
            <button
              className={"Button " + styles.SubscribeButton}
              onClick={this.openSubscribeModal}
            >Subscribe to Location</button>
          </div>
        }

        <div className="PaddedSection">
          <div className={sidebarStyles.SidebarLayout}>
            <div className="Container">
              <div className={sidebarStyles.SidebarLayout_body}>
                <div className={styles.LocationDetail_events}>
                  {locationResource && (
                    <Scroll.Element name="schedule">
                      <PublicEvents
                        location={locationResource.id}
                        showTrucks
                        includeMeta={{
                          type: 'location',
                          url: url,
                          name: locationResource.name ? locationResource.name : locationResource.filtered_address,
                          address: locationResource.address,
                          image: image,
                          description: `Food ${cityConfig.vehicle_type}s at ${name}`
                        }}
                        withActiveTrucks
                        includeBookings
                        title="Upcoming location events"
                      />
                    </Scroll.Element>
                  )}
                </div>

                {/* <div className="Advertisement u-mb1">
                  <Ad slot="7735243397" />
                </div> */}
              </div>
              <div className={sidebarStyles.SidebarLayout_sidebar}>
                <Sidebar />
              </div>
            </div>
          </div>
        </div>

        { showSubscribeModal && locationResource && locationResource.pod &&
          <Modal>
            <h4>Subscribe to {locationResource.name}</h4>
            <p>Enter your email to receive weekly updates on {cityConfig.vehicle_type.toLowerCase()}s scheduled to be at this location.</p>
            <LocationSubscribeForm onSubmit={this.onSubscribeSubmit} onClose={this.closeSubscribeModal} />
          </Modal>
        }
        { showSubscribeSuccessModal &&
          <Modal>
            <div className={modalStyles.ModalContent__center}>
              <h2>Thank You!</h2>
              <p>You have been subscribed.</p>
              <button
                className="Button Button--small"
                onClick={this.closeSubscribeSuccessModal}
              >
                Ok
              </button>
            </div>
          </Modal>
        }

      </div>
    );
  }
}

Location.propTypes = {
  fetchLocation: PropTypes.func,
  clearLocations: PropTypes.func,
  addSubscriber: PropTypes.func,
  locationResource: PropTypes.object,
  clearServerRenderedPath: PropTypes.func,
  match: PropTypes.object,
  ui: PropTypes.object,
  computedMatch: PropTypes.object,
  cityConfig: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    locationResource: getLocation(state, props),
    ui: state.ui,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchLocation(id) {
      dispatch(locationActions.fetchOne(id));
    },
    clearLocations(id) {
      return dispatch(locationActions.clear());
    },
    addSubscriber(id, email) {
      return dispatch(locationActions.addSubscriber(id, email))
    },
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Location));
